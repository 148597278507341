<template>
	<div style="height: 300px" ref="editor"></div>
</template>

<script src="https://cdn.quilljs.com/1.3.7/quill.js"></script>
<script>
import Quill from 'quill'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.bubble.css'
import 'quill/dist/quill.snow.css'

export default {
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      editor: null,
    }
  },
  mounted() {
    var _this = this

    this.editor = new Quill(this.$refs.editor, {
      modules: {
        toolbar: [
          [{ font: [] }, { size: [] }],
          ['bold', 'italic', 'underline', 'strike'],
          // ['blockquote'],
          // [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          ['link'],
        ],
      },
      theme: 'snow',
      formats: ['bold', 'italic', 'link', 'strike', 'script', 'underline'],
      placeholder: 'Type something in here!',
    })
    this.editor.root.innerHTML = this.modelValue
    this.editor.on('text-change', function () {
      return _this.update()
    })
  },

  methods: {
    update: function update() {
      this.$emit(
        'update:modelValue',
        this.editor.getText() ? this.editor.root.innerHTML : ''
      )
    },
  },
}
</script>
